
/**
 * @description
 * 수업 > 재능 > 이번주 인기 급상승 큐레이션
 */

import { mapActions } from 'vuex';

import { Swiper } from 'vue-awesome-swiper';
import TrendingList from './TrendingList.vue';
import TrendingSkeleton from './TrendingSkeleton.vue';

export default {
  name: 'Trending',
  components: { TrendingList, Swiper, TrendingSkeleton },
  data() {
    return {
      trendingSwiper: null,
      trendingSwiperOption: {
        slidePerView: 'auto',
        loop: false,
        parallax: true,
        effect: 'slide',
        mouseWheel: false,
        spaceBetween: 20,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false, // 인터렉션 후 자동재생 다시 실행하게
        },
        navigation: {
          nextEl: '.mobile-button__next',
          prevEl: '.mobile-button__prev',
        },
      },
      trendingIcon: require('@/assets/img/home/trending.svg'),
      trendingList: [],
    };
  },
  fetchOnServer: false,
  async fetch() {
    try {
      this.trendingList = await this.getTrendingList();
    } catch (e) {
      console.error(e);
    }
  },
  computed: {
    trendingListFirst: ({ trendingList }) => trendingList.slice(0, 5),
    trendingListSecond: ({ trendingList }) => trendingList.slice(5, 10),
  },
  mounted() {
    this.trendingSwiper = this.$refs.trendingSwiper?.$swiper;
  },
  methods: {
    ...mapActions('new-home/talent', ['getTrendingList']),
  },
};
