
/**
 * @description
 * 홈 > 재능 > 이번주 인기 급상승 아이템 리스트
 */

import 'swiper/css/swiper.css';
import { SwiperSlide } from 'vue-awesome-swiper';

import TrendingItem from './TrendingItem.vue';

export default {
  name: 'TrendingList',
  components: { SwiperSlide, TrendingItem },
  props: {
    trendingList: { type: Array, default: () => [] },
  },
};
