
/**
 * @description
 * 수업 > 재능 > 이번주 인기 급상승 큐레이션 아이템 > 썸네일
 */

import Thumbnail from '@/components/common/product/Thumbnail.vue';

export default {
  name: 'TrendingThumbnail',
  components: { Thumbnail },
  props: {
    thumbnailImg: { type: String, default: null },
    rank: { type: Number, default: 0 }, // 인기 순위
  },
};
